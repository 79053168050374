import { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, updateProfile, signOut, getIdToken } from "firebase/auth";
import initializeFirebase from "../components/Shared/protectedArea/Firebase/firebase.init";

// Initialize Firebase App
initializeFirebase();
const useFirebase = () => {
	const [user, setUser] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [authError, setAuthError] = useState('');
	const [admin, setAdmin] = useState(false);
	const [token, setToken] = useState('');

	const auth = getAuth();
	const registerUser = (email, password, name, navigate) => {
		setIsLoading(true);
		createUserWithEmailAndPassword(auth, email, password)
			.then(
				(userCredential) => {
					setAuthError('');
					const newUser = { email, displayName: name };
					setUser(newUser);
					// Save user to the database
					saveUser(email, name, 'POST');
					// Send name to firebase after creation
					updateProfile(auth.currentUser, {
						displayName: name
					}).then(() => {
					}).catch((error) => {
					});
					navigate(-2);
				}
			)
			.catch((error) => {
				setAuthError(error.message);
			})
			.finally(() => setIsLoading(false));
	}

	const loginUser = (email, password, location, navigate) => {
		setIsLoading(true);
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				const destination = location?.state?.from || '/';
				navigate(destination);
				setAuthError('');
			})
			.catch((error) => {
				setAuthError(error.message);
			})
			.finally(() => setIsLoading(false));
	}

	// Observer User State
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
				getIdToken(user)
					.then(idToken => {
						setToken(idToken);
					})
			} else {
				setUser({})
			}
			setIsLoading(false);
		});
		return () => unsubscribe;
	}, []);

	useEffect(() => {
		fetch(`https://secure-crag-50348.herokuapp.com/users/${user.email}`)
			.then(res => res.json())
			.then(data => setAdmin(data.admin))
	}, [user.email]);

	const logout = () => {
		setIsLoading(true);
		signOut(auth)
			.then(() => {
				// Sign-out successful.
			})
			.catch((error) => {
				// An error happened.
			})
			.finally(() => setIsLoading(false));
	}

	const saveUser = (email, displayName) => {
		const user = { email, displayName };
		fetch('https://secure-crag-50348.herokuapp.com/users', {
			method: "POST",
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify(user)
		})
			.then()
	}

	return {
		user,
		admin,
		token,
		isLoading,
		authError,
		registerUser,
		loginUser,
		logout,
	}
}

export default useFirebase;