const firebaseConfig = {
	apiKey: "AIzaSyCesfFbmG5JGEvO2UZbS3TqFEdtrkdZxPs",
	authDomain: "mi-japan.firebaseapp.com",
	projectId: "mi-japan",
	storageBucket: "mi-japan.appspot.com",
	messagingSenderId: "534015704673",
	appId: "1:534015704673:web:a6f63779386e01ccfb7420",
	measurementId: "G-35TEMXX9PD"
};

export default firebaseConfig;

