import { Box } from '@mui/material';
import React from 'react';
import Reports from '../Reports/Reports';

const AdminPanelHome = () => {
  return (
    <Box>
      <Reports />
    </Box>
  );
};

export default AdminPanelHome;